<template>
  <div class="container-fluid py-4 add-student online-course">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">
                تفاصيل الدورة - أونلاين
              </h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2">
            <form role="form" class="text-start mt-3">
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label> اسم الدورة</label>
                  <vmd-input id="" type="text" :label="course.name" disabled />
                </div>
                <div class="mb-3 col-lg-6">
                  <label> سعر الدورة</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.total_cost"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label> المادة</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.subject"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label> المدرس</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.instructor"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label> الفرقة</label>
                  <vmd-input id="" type="text" :label="course.grade" disabled />
                </div>
                <div class="mb-3 col-lg-6">
                  <label> السنة الدراسية</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.academic_year"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label> رسوم التطبيق </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.app_fees"
                    disabled
                  />
                </div>
                <div class="mb-3 col-lg-6">
                  <label> عدد الفيديوهات</label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.videos ? course.videos.length : 0"
                    disabled
                  />
                </div>
              </div>
              <div class="row">
                <div class="mb-3 col-lg-6">
                  <label> عدد الطلاب في الدورة </label>
                  <vmd-input
                    id=""
                    type="text"
                    :label="course.total_students"
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- promocode -->
      <!-- lectures -->
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">البروموكود</h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase font-weight-bolder">الكود</th>
                      <th class="text-uppercase font-weight-bolder">
                        الطالب المستخدم للكود
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                        تاريخ الإنشاء
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in promoCode" :key="item.id">
                      <td>
                        <p class="text-s mb-0">{{ item.value }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0">
                          {{ item.student ? item.student.full_name : "" }}
                        </p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ item.created_at }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- lectures -->
      <div class="row">
        <h6 class="bg-gradient-warning grade-filter ">
          يمكن السحب لأعلي وأسفل لتغيير ترتيب الفيديوهات
        </h6>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <div class="row">
                  <div class="col-lg-6">
                    <h6 class="text-white text-capitalize ps-3">الفيديوهات</h6>
                  </div>
                  <div class="col-lg-6">
                    <span
                      @click="getCoursesList"
                      class="pointer badge badge-sm new-student"
                      ><i class="fas fa-copy"></i> نسخ الفيديوهات
                    </span>
                    <router-link v-if="serverUpload === '1'"
                      :to="{
                        path: '/courses/' + courseId + '/add-video',
                        params: { id: courseId },
                      }"
                    >
                      <span class="badge badge-sm new-student"
                        ><i class="fas fa-location-arrow"></i>إضافة فيديو
                      </span>
                    </router-link>
                    <router-link v-else
                      :to="{
                        path: '/courses/' + courseId + '/youtube-video',
                        params: { id: courseId },
                      }"
                    >
                      <span class="badge badge-sm new-student"
                        ><i class="fas fa-location-arrow"></i>إضافة فيديو
                      </span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase font-weight-bolder">الاسم</th>
                      <th class="text-uppercase font-weight-bolder">
                        تكلفة المحاضرة
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                        التسجيل في الفيديو
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                         البرموكود
                      </th>
                      <th class="text-uppercase font-weight-bolder">
                        العمليات
                      </th>
                    </tr>
                  </thead>
                  <draggable
                    v-model="videos"
                    tag="tbody"
                    item-key="id"
                    @change="sortingVideos"
                  >
                    <template #item="{ element }">
                      <tr style="cursor: pointer">
                        <td>
                          <i class="fas fa-sort"></i>
                          <router-link
                            :to="{
                              path: '/videos/' + element.id + '/show',
                              params: { vid_id: element.id },
                            }"
                            class="link"
                            >{{ element.name }}</router-link
                          >
                        </td>
                        <td>{{ element.cost }}</td>
                        <td class="text-center">
                          <span
                            @click="showEnrollModal(element.id, element.name)"
                            class="badge badge-sm bg-gradient-warning pointer mx-1"
                          >
                            تسجيل</span
                          >
                        </td>
                         <td class="text-center">
                          <span
                            @click="goPromoCode(element.id, element.name)"
                            class="badge badge-sm bg-gradient-info pointer mx-1"
                          >
                            إضافة</span
                          >
                        </td>
                        <td class="actions">
                          <router-link
                            :to="{
                              path: '/videos/' + element.id + '/show',
                              params: { vid_id: element.id },
                            }"
                            class="link"
                            ><a href="javascript:void(0);" data-action="show"
                              ><i class="fas fa-eye" title="عرض"></i>
                            </a>
                          </router-link>
                          <router-link
                            :to="{
                             path: '/videos/' + element.id + '/edit-video',
                              params: { vid_id: element.id },
                            }"
                            class="link"
                          >
                            <a href="javascript:void(0);" data-action="edit"
                              ><i class="fas fa-edit"></i>
                            </a>
                          </router-link>
                          <span @click="deleteVideo(element.id)">
                            <a href="javascript:void(0);" data-action="delete"
                              ><i class="fas fa-trash" title="حذف"></i>
                            </a>
                          </span>
                        </td>
                      </tr>
                    </template>
                  </draggable>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- students -->
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                  الطلاب المسجلين في الدورة : {{ students?.length }} طالب
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive p-0">
                <table class="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase font-weight-bolder">رقم</th>
                      <th class="text-uppercase font-weight-bolder">الاسم</th>
                      <th class="text-uppercase font-weight-bolder">
                        رقم الهاتف
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="student in students" :key="student.id">
                      <td>
                        <p class="text-s mb-0">{{ student.number }}</p>
                      </td>
                      <td>
                        <p class="text-s mb-0"><router-link class="pointer to-link" :to="{path:'/students/' +student.id+'/show'}">{{ student.full_name }}</router-link></p>
                      </td>
                      <td>
                        <p class="text-s mb-0">{{ student.mobile }}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- video enroll modal -->
    <vue-final-modal
      v-model="showModal"
      name="example"
      classes="modal-container s-info-modal course-modal"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                  تسجيل الطالب في فيديو : {{ videoInfo.name }}
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="row modal-row">
                <div class="mb-3 col-lg-8">
                  <label>
                    ادخل رقم الطالب أو الهاتف<span class="red">*</span></label
                  >
                  <vmd-input
                    id="student-number"
                    type="text"
                    placeholder="  رقم الطالب او الهاتف "
                    v-model="student.searchVal"
                    @keyup.enter="search"
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="table-responsive p-0 overflow student-info">
                  <table
                    style="margin: auto"
                    class="table align-items-center mb-0"
                  >
                    <thead>
                      <tr>
                        <th class="text-uppercase font-weight-bolder">
                          رقم الطالب
                        </th>
                        <td>{{ student.number }}</td>
                      </tr>

                      <tr>
                        <th class="text-uppercase font-weight-bolder">
                          اسم الطالب
                        </th>
                        <td>{{ student.full_name }}</td>
                      </tr>
                      <tr>
                        <th class="text-uppercase font-weight-bolder">
                          رقم الهاتف
                        </th>
                        <td>{{ student.mobile }}</td>
                      </tr>
                      <tr v-if="centerType !== 'university'">
                        <th class="text-uppercase font-weight-bolder">
                          الفرقة
                        </th>
                        <td>{{ student.grade_name }}</td>
                      </tr>
                      <tr
                        v-if="
                          centerType !== 'instructor' &&
                          centerType !== 'university'
                        "
                      >
                        <th class="text-uppercase font-weight-bolder">
                          المجموعة
                        </th>
                        <td>{{ student.group_name }}</td>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="text-center col-lg-6">
                  <vmd-button
                    @click="enrollVideo"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="info"
                  >
                    حفظ</vmd-button
                  >
                </div>
                <div class="text-center col-lg-6">
                  <vmd-button
                    type="reset"
                    class="my-4 mb-2"
                    variant="gradient"
                    color="danger"
                    @click="showModal = false"
                  >
                    إلغاء
                  </vmd-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
    <!-- modal -->
    <!-- copy videos model -->
    <vue-final-modal
      v-model="copyVideoModal"
      name="example"
      classes="modal-container course-modal"
      content-class="modal-content"
    >
      <div class="row">
        <div class="col-12">
          <div class="card my-4">
            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
              <div
                class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
              >
                <h6 class="text-white text-capitalize ps-3">
                  اختر الدورة المطلوب نسخ الفيديوهات اليها
                </h6>
              </div>
            </div>
            <div class="card-body px-0 pb-2">
              <form @submit="copyVideos" role="form" class="text-start mt-3">
                <div class="row modal-row">
                  <div class="mb-3 col-lg-9">
                    <label>
                      اختر الدورة <span class="red">*</span>
                      <span color="danger" class="error">{{}}</span>
                    </label>
                    <multiselect
                      class="required form-control"
                      id="room"
                      v-model="selectedCourse"
                      :options="courses"
                      :searchable="true"
                      :close-on-select="true"
                      placeholder="  اختر الدورة"
                      label="name"
                      :custom-label="customLabel"
                      track-by="name"
                      required="required"
                      :internal-search="true"
                    >
                      >
                      <template v-slot:noOptions>
                        <span> فارغة </span>
                      </template>
                      <template v-slot:noResult>
                        <span>لا يوجد نتيجة </span>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="row">
                  <div class="text-center col-lg-6">
                    <vmd-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="info"
                    >
                      حفظ</vmd-button
                    >
                  </div>
                  <div class="text-center col-lg-6">
                    <vmd-button
                      type="reset"
                      class="my-4 mb-2"
                      variant="gradient"
                      color="danger"
                      @click="copyVideoModal = false"
                    >
                      إلغاء
                    </vmd-button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>
<script>
import VmdInput from '@/components/VmdInput.vue'
import VmdButton from '@/components/VmdButton.vue'
import draggable from 'vuedraggable'
import { VueFinalModal } from 'vue-final-modal'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'ShowOnlineCourse',
  components: {
    VmdInput,
    VmdButton,
    draggable,
    VueFinalModal
  },
  data () {
    return {
      course: {},
      student: {},
      courseId: '',
      groupArray: '',
      videos: [],
      videoInfo: {},
      showModal: false,
      copyVideoModal: false,
      students: [],
      promoCode: [],
      courses: [],
      selectedCourse: '',
      serverUpload: null
    }
  },
  created () {
    this.serverUpload = localStorage.serverUpload
    this.courseId = this.$route.params.id
    this.$http
      .get(`${this.$hostUrl}courses/${this.$route.params.id}`)
      .then((response) => {
        this.course = response.data.data
        const sliceEnd = this.course.groups.length - 1
        this.groupArray = this.course.groups
          .split(' ')
          .join('-')
          .slice(0, sliceEnd)
        this.videos = this.course.videos
        this.students = this.course.students
        this.promoCode = this.course.promo_code
      })
  },
  methods: {
    async sortingVideos (e) {
      const videosId = []
      $.each(this.videos, (key, val) => {
        videosId.push(val.id)
        console.log(val.name)
      })
      const formData = new FormData()
      formData.append('ids', JSON.stringify(videosId))

      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'sort',
        ''
      )
    },
    deleteVideo (id) {
      swal({
        title: 'هل انت متأكد ؟',
        text: 'بمجرد الموافقة ، سيتم حذف الفيديو  !',
        icon: 'warning',
        buttons: ['لا', 'نعم'],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .delete(`${this.$hostUrl}videos/${id}`, { _method: 'delete' })
            .then((response) => {
              if (!response.data.error) {
                swal({ title: response.data.message, buttons: 'تم' })
                this.$http
                  .get(`${this.$hostUrl}courses/${this.$route.params.id}`)
                  .then((response) => {
                    this.course = response.data.data
                    this.videos = this.course.videos
                  })
              }
            })
            .catch(function (error) {
              if (Object.prototype.hasOwnProperty.call(error, 'response')) {
                swal({ title: error.response.data.message, buttons: 'تم' })
              }
            })
        } else {
          swal({ title: ' تم الإلغاء  !', buttons: 'تم' })
        }
      })
    },
    showEnrollModal (id, name) {
      this.student = {}
      this.showModal = true
      this.videoInfo = {
        name: name,
        id: id
      }
    },
    async enrollVideo (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('student_id', this.student.id)
      formData.append('video_id', this.videoInfo.id)

      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'video/enroll',
        ''
      )
      if (!this.errors.video) {
        this.showModal = false
      }
    },
    search () {
      this.student.id = null
      this.student.full_name = null
      this.student.grade_name = null
      this.student.mobile = null
      if (!this.student.searchVal.length) {
        return false
      }
      this.$http
        .get(`${this.$hostUrl}students-list?search=${this.student.searchVal}`)
        .then((response) => {
          if (response.data.data.length === 1) {
            this.student = response.data.data[0]
          }
          if (response.data.error) {
            swal(response.data.message)
          }
        })
    },
    getCoursesList () {
      this.copyVideoModal = true
      this.$http
        .get(`${this.$hostUrl}courses?grade_id=${this.course.grade_object.id}`)
        .then((response) => {
          this.courses = response.data.data.filter((item) => {
            console.log(item.instructor.id, this.course.instructor.id)
            return (
              item.type === 1 &&
              item.id !== this.$route.params.id &&
              item.instructor_object.id === this.course.instructor_object.id
            )
          })
        })
    },
    async copyVideos (e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('old_course_id', this.$route.params.id)
      formData.append('new_course_id', this.selectedCourse.id)

      this.errors = await this.postRequest(
        formData,
        this.$hostUrl + 'copy/videos',
        ''
      )
      // if (!this.errors.video) {
      this.copyVideoModal = false
      this.selectedCourse = {}
      // }
    },
    goPromoCode (videoId, videoName) {
      this.$router.push({
        path: `/courses/${videoId}/video-promocode`,
        params: { id: videoId, name: videoName }
      })
    }
  }
}
</script>
<style>
.message {
  padding: 10px;
}
.link {
  color: blue !important;
  text-decoration: underline;
}
.fa-sort {
  padding-left: 8px;
}
.actions {
  display: flex;
  gap: 18px;
}
.online-course .form-label {
  color: #00000b !important;
  font-size: 14px !important;
  font-weight: 500;
}
</style>
